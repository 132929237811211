import React from "react"
import { StaticImage } from "gatsby-plugin-image"
export default function Image({ name, booked, title, src }) {
  let titleText

  if (booked) titleText = `Book ${name}`
  else if (title) titleText = title
  else titleText = name

  return (
    <div className="rounded-xl overflow-hidden grid items-end justify-items-center h-auto">
      <div className="col-start-1 row-start-1 relative z-0 w-full h-[50vh] lg:h-[50vh]">
        {src === "undefined" ? (
          <StaticImage
            src="../../../static/img/villa_request_header.png"
            alt="Boat"
            className="object-cover lg:object-contain h-full w-full"
          />
        ) : (
          <div className="grid">
            <img
              src={src}
              alt="Boat"
              className="object-cover h-[50vh] lg:h-[50vh] w-full col-start-1 row-start-1 relative z-0 "
            />
            <div className="col-start-1 row-start-1 relative z-10 h-full w-full bg-black bg-opacity-50" />
          </div>
        )}
      </div>
      <div className="col-start-1 row-start-1 relative z-10 pb-6">
        <h1 className="text-white filter drop-shadow-md">{titleText}</h1>
      </div>
    </div>
  )
}
